import * as React from 'react';
import * as classNames from 'classnames';
import { ErrorMessage } from './ErrorMessage';
import { StarIcon } from 'library/icons/Star';

export declare type FormGroupClasses = 'select-wrapper' | null;

declare namespace FormGroup {
  export interface Props {
      label?: string;
      testId?: string;
      labelFor?: string;
      labelId?: string;
      hasErrors?: boolean;
      isValid?: boolean;
      required?: boolean;
      additionalWrapper?: FormGroupClasses;
      validationMessages?: string[];
      description?: string;
      children?: React.ReactNode | React.ReactNode[];
      readOnly?: boolean;
  }

  export interface State {

  }
}

export class FormGroup extends React.Component<FormGroup.Props, FormGroup.State> {
  render() {
    const { label, testId, labelFor, labelId, hasErrors, isValid, additionalWrapper, children, description, validationMessages, required, readOnly } = this.props;

    const hasLabel = label && label.length > 0
    const formGroupClasses = classNames({
      'form-offset-group': !hasLabel,
      'form-group--readonly': readOnly
    }, 'form-group');
    const formInputClasses = classNames({
      'has-error': hasErrors,
      'has-success': isValid,
      [additionalWrapper]: additionalWrapper && additionalWrapper.length > 0
    }, 'form-input')

    return (
      <div className={ formGroupClasses } data-test={ testId || labelFor }>
        {
          hasLabel &&
          <label htmlFor={ labelFor } id={ labelId } className="control-label col-sm-1">
            { label }

            { required &&
              <span className='control-label__required-icon' title="Required">
                <StarIcon height={7} width={7} />
              </span>
            }

          </label>
        }
        <span className={ formInputClasses }>
          { children }
          { description &&
            <span className="form-help-description">{description}</span>
          }
          {
            validationMessages &&
            validationMessages.map((msg, idx) => <ErrorMessage key={ idx } propertyName={ labelFor } errorMessage={ msg } />)
          }
        </span>
      </div>
    )
  }
}
