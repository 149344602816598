import * as React from 'react';
import { useDefaultSvgDims } from './useDefaultSvgDims';

export const MagicIcon : React.SFC<React.SVGProps<SVGSVGElement> & SvgTitleProps> = (props) => {
  const { title, svgProps } = useDefaultSvgDims(props);

  return(
    <svg {...svgProps} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
      { title &&
        <title>{title}</title>
      }
      <path d="M1254 581l293-293-107-107-293 293zm447-293q0 27-18 45l-1286 1286q-18 18-45 18t-45-18l-198-198q-18-18-18-45t18-45l1286-1286q18-18 45-18t45 18l198 198q18 18 18 45zm-1351-190l98 30-98 30-30 98-30-98-98-30 98-30 30-98zm350 162l196 60-196 60-60 196-60-196-196-60 196-60 60-196zm930 478l98 30-98 30-30 98-30-98-98-30 98-30 30-98zm-640-640l98 30-98 30-30 98-30-98-98-30 98-30 30-98z"/>
    </svg>
  )
}
