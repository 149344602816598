import { newCompoundPredicateId, newPredicateId } from "library/code/id-generator";
import { useReducer, useMemo } from "react";
import { compoundPredicateSchema } from "library/applicability/redux/normalize";
import * as dotProp from 'dot-prop-immutable';
import { normalize, denormalize } from 'normalizr';
import { nameof } from "library/code/generics";

/**
 * useApplicability is a lighter approach to using the PredicateGroup components
 * because it does not require the full store and action
 * @param predicateGroups groups you wish to manage with the hook
 */
export function useApplicability(predicateGroups: CompoundPredicate[]) : [CompoundPredicate[], ApplicabilityActions] {

  const initialState = useMemo(() => JSON.parse(JSON.stringify(predicateGroups)), []);
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(() => {
    return {
      addCompoundPredicate: () => {
        dispatch({ type: 'CREATE_COMPOUND_PREDICATE' })
      },
      deleteCompoundPredicate: (payload: DeleteCompoundPredicatePayload) => {
        dispatch({ type: 'DELETE_COMPOUND_PREDICATE', payload })
      },
      addPredicate: (payload: CreatePredicatePayload) => {
        dispatch({ type: 'CREATE_PREDICATE', payload })
      },
      updatePredicate: (payload: UpdatePredicatePayload) => {
        dispatch({ type: 'UPDATE_PREDICATE', payload })
      },
      deletePredicate: (payload: DeletePredicatePayload) => {
        dispatch({ type: 'DELETE_PREDICATE', payload })
      }
    } as ApplicabilityActions
  }, [state, dispatch]);

  return [state, actions];
}

type ApplicabilityAction =
  { type: 'CREATE_COMPOUND_PREDICATE' } |
  { type: 'DELETE_COMPOUND_PREDICATE', payload: DeleteCompoundPredicatePayload } |
  { type: 'CREATE_PREDICATE', payload: CreatePredicatePayload } |
  { type: 'UPDATE_PREDICATE', payload: UpdatePredicatePayload } |
  { type: 'DELETE_PREDICATE', payload: DeletePredicatePayload }

const reducer = function(state: CompoundPredicate[], action: ApplicabilityAction) {

  const entitiesPath = nameof<NormalisedPredicateGroups>('entities');
  const predicatesPath = nameof<NormalisedEntities>('predicates');

  switch (action.type) {
    case 'CREATE_COMPOUND_PREDICATE':
      var newId = newCompoundPredicateId();
      let newCompound: CompoundPredicate = {
          id: newId,
          predicates: [],
          failMessageOverride: ''
      }

      return [...state, newCompound];

    case 'DELETE_COMPOUND_PREDICATE':
      var deleteCompoundId = action.payload.compoundPredicateId as string;

      return state.filter(w => w.id != deleteCompoundId);

    case 'CREATE_PREDICATE':
      let createPayload = action.payload;
      let createInCompoundId = createPayload.compoundPredicateId;

      var newId = newPredicateId(createInCompoundId);
      var newPredicate: RulePredicate = {
          type: createPayload.type,
          data: createPayload.data,
          summary: createPayload.summary,
          id: newId
      };

      return state.map(group => {
          if (group.id == createInCompoundId) {
            return {
              ...group,
              predicates: [...group.predicates, newPredicate]
            } as CompoundPredicate
          }

          return group;
        });

    case 'UPDATE_PREDICATE':
      var updatedPredicate = action.payload.predicate as RulePredicate;

      // I've left this using normalizr for now, as I don't have the compoundPredicateId and this is simpler atm.
      var normalized = normalisePredicateGroups(state);
      normalized = dotProp.merge(normalized,
          `${entitiesPath}.${predicatesPath}.${updatedPredicate.id}`,
          {
              data: updatedPredicate.data,
              summary: updatedPredicate.summary
          }
      )

      return denormalisePredicateGroups(normalized);

    case 'DELETE_PREDICATE':
      let predicateId = action.payload.predicateId as string;
      let deleteFromCompoundId = action.payload.compoundPredicateId as string;

      return state.map(group => {
        if (group.id == deleteFromCompoundId) {
          return {
            ...group,
            predicates: group.predicates.filter(predicate => predicate.id != predicateId)
          } as CompoundPredicate
        }

        return group;
      });
  }

  return state;
}

interface NormalisedPredicateGroups {
  result: {
    compoundPredicates: string[]
  },
  entities: NormalisedEntities
}

interface NormalisedEntities {
  compoundPredicates: {
    [key: string] : CompoundPredicateNormalized
  },
  predicates: {
      [key: string] : RulePredicate
  }
}

function normalisePredicateGroups(groups: CompoundPredicate[]) : NormalisedPredicateGroups {
  return normalize({ compoundPredicates: groups }, compoundPredicateSchema);
}

function denormalisePredicateGroups(normalisedState: NormalisedPredicateGroups) : CompoundPredicate[] {
  var output = denormalize({ compoundPredicates: normalisedState.result.compoundPredicates }, compoundPredicateSchema, normalisedState.entities);
  return output.compoundPredicates;
}
