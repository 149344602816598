import { schema, normalize, denormalize } from 'normalizr';

const predicateTypesSchema = new schema.Entity('predicateTypes', {}, { idAttribute: 'type' });
const predicateSchema = new schema.Entity('predicates');
const compoundSchema = new schema.Entity('compoundPredicates', {
    predicates: [ predicateSchema ]
});
const applicabilityModelSchema = {
    compoundPredicates: [ compoundSchema ],
    predicateTypes: [ predicateTypesSchema ]
}
export const compoundPredicateSchema = {
  compoundPredicates: [ compoundSchema ]
}

export const normaliseModel = function(data: ApplicabilityModel) {
    return normalize(data, applicabilityModelSchema);
}

export const denormaliseModel = function(data: ApplicabilityState) : ApplicabilityModel {
    return denormalize(data.result, applicabilityModelSchema, data);
}

export const denormaliseCompoundPredicate = function(entities: any, id: string) {
    const { compoundPredicates } = denormalize({ compoundPredicates: [id] }, applicabilityModelSchema, entities);

    return compoundPredicates[0];
}
