import * as React from 'react';

interface PlaneProps {
  height?: number;
  width?: number;
}

export function Plane(props: PlaneProps) {
  const height = props.height ? props.height : 16;
  const width = props.width ? props.width : 16;

  return(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M480 192H365.7L260.6 8.1A16 16 0 0 0 246.7 0h-65.5c-10.6 0-18.3 10.2-15.4 20.4L214.9 192H112l-43.2-57.6c-3-4-7.8-6.4-12.8-6.4H16C5.6 128-2 137.8 .5 147.9L32 256 .5 364.1C-2 374.2 5.6 384 16 384H56c5 0 9.8-2.4 12.8-6.4L112 320h102.9l-49 171.6c-2.9 10.2 4.8 20.4 15.4 20.4h65.5c5.7 0 11-3.1 13.9-8.1L365.7 320H480c35.4 0 96-28.7 96-64s-60.7-64-96-64z"/></svg>
  )
}

