import * as React from 'react';
import { useDefaultSvgDims } from './useDefaultSvgDims';

export const TrashIcon : React.SFC<React.SVGProps<SVGSVGElement> & SvgTitleProps> = (props) => {
  const { title, svgProps } = useDefaultSvgDims(props);

  return(
    <svg {...svgProps} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
      { title &&
        <title>{title}</title>
      }
      <path d="M704 1376V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V672q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM672 384h448l-48-117q-7-9-17-11H738q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H480q-66 0-113-58.5T320 1464V512h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/>
    </svg>
  )
}

