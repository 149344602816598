import * as React from 'react';

declare namespace ListComponent {
  export interface Props {
      listOptions: AjaxLookupResult[];
      selectedValues: string[];
      onChange: (options: AjaxLookupResult[]) => void;
  }

  export interface State {

  }
}

export class ListComponent extends React.Component<ListComponent.Props, ListComponent.State> {
  render() {
    const { listOptions, selectedValues } = this.props;

    var mappedOptions = listOptions.map(option => {
      return {
        option: option,
        isSelected: selectedValues.indexOf(option.value) > -1
      }
    })

    return (
      <div className="multiple-text-inputs form-subgroup-stacked">
        { mappedOptions.map(m =>
          <label key={ m.option.value }>
            <input type="checkbox"
              value={m.option.value}
              checked={m.isSelected}
              onChange={ () => this.onClick(m.option, m.isSelected) } />
            {m.option.label}
          </label>
        ) }
      </div>
    )
  }

  onClick(option: AjaxLookupResult, currentlySelected: boolean) {
    const { selectedValues, listOptions } = this.props;

    var newSelectedValues = [];
    if (currentlySelected) {
      newSelectedValues = selectedValues.filter(w => w != option.value);
    } else {
      newSelectedValues = [...selectedValues, option.value];
    }

    var selectedOptions = listOptions.filter(w => newSelectedValues.indexOf(w.value) > -1 );

    this.props.onChange(selectedOptions);
  }
}
