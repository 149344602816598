import { ErrorMessageMap } from './ErrorMessageFor';
import * as dotProp from 'dot-prop-immutable';

export class ErrorMessageHelper<T = void> {
  errors: ErrorMessageMap;

  constructor(errorMap?: ErrorMessageMap) {
    this.errors = errorMap || {};
  }

  addErrorFor(propertyName: keyof T, errorMessage: string) {
    this.addErrorForKey(propertyName as string, errorMessage);
  }
  
  addErrorForKey(key: string, errorMessage: string) {
    this.errors = dotProp.merge(this.errors, [key], [errorMessage]);
  }

  errorsFor(propertyName: keyof T) : string[] {
    return this.errorsForKey(propertyName as string);
  }

  errorsForKey(key: string) : string[] {
    if (this.errors[key]) {
      return this.errors[key];
    }

    return [];
  }

  isValid() {
    return Object.keys(this.errors)
      .every(a => a.length == 0);
  }
}

