import * as React from 'react';

declare namespace User {
  export interface Props {
    height?: number;
    width?: number;
  }

  export interface State {
  }
}

export class User extends React.PureComponent<User.Props, User.State> {
  render() {
    const height = this.props.height ? this.props.height : '15'
    const width = this.props.width ? this.props.width : '15'

    return(
      <svg width={width} height={height} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M896 0q182 0 348 71t286 191 191 286 71 348q0 181-70.5 347t-190.5 286-286 191.5-349 71.5-349-71-285.5-191.5-190.5-286-71-347.5 71-348 191-286 286-191 348-71zm619 1351q149-205 149-455 0-156-61-298t-164-245-245-164-298-61-298 61-245 164-164 245-61 298q0 250 149 455 66-327 306-327 131 128 313 128t313-128q240 0 306 327zm-235-647q0-159-112.5-271.5t-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5 271.5-112.5 112.5-271.5z"/></svg>
    )
  }
}
